/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import CONSTANTS from "@/constants"

export default {
  /**
   * This action will notify users
   * @param {*} context is the store.
   * @param {*} notification is the notification content.
   */
  async notify(context, notification) {
    if (!context.getters.showingPermanentNotification || notification.retainSnackbar) {
      context.commit("setNotify", true)
      context.commit("setNotification", notification)

      if (!notification.retainSnackbar) {
        const notificationTimeout = setTimeout(() => {
          context.commit("setNotify", false)
          context.commit("setNotification", undefined)
        }, CONSTANTS.NOTIFICATION_TIMEOUT)
        context.commit("setPermanentNotification", notificationTimeout)
      } else {
        const notificationTimeout = context.getters.notificationTimeout
        clearTimeout(notificationTimeout)
        context.commit("setNotificationTimeout", undefined)
        context.commit("setPermanentNotification", true)
      }
    }
  },

  /**
   * This action will suppress notification.
   * @param {*} context is the store.
   */
  suppressNotification(context) {
    context.commit("setNotify", false)
    context.commit("setNotification", undefined)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setBreadcrumbs", undefined)
    context.commit("setPageTitle", undefined)
    context.commit("setPageFreezed", false)
    context.commit("setNotify", false)
  }
}