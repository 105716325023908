import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DATA_EXPORT_TYPE } from "@/constants"
import { PAGES } from "@/constants"

/**
* This method will be called before navigating to Analytics page.
*/
export const beforeAnalytics = async (to, from)  => {
  const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
  if (!loggedInUserPolicies["Analytics view"]) {
    return "dashboard"
  }

  if (from.name !== "analytics" || !(PAGES.ANALYTICS.includes(to.hash) || PAGES.ANALYTICS.includes(from.hash))) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)
    store.dispatch("kpis/loadKPIs")

    if (loggedInUserPolicies["Export view"]) {
      store.dispatch("exports/loadExports", { type: DATA_EXPORT_TYPE.ANALYTICS })
    }

    if (loggedInUserPolicies["Domain view"]) {
      store.dispatch("domains/loadDomains")
    }
    if (loggedInUserPolicies["Channel view"]) {
      store.dispatch("channels/loadChannels")
    }
    if (loggedInUserPolicies["Language view"]) {
      store.dispatch("languages/loadLanguages")
    }
  }
}