import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ISSUE } from "@/constants/bread-crumbs/issue"
import { TASK } from "@/constants/bread-crumbs/task"
import { BOOLEAN } from "@/constants"
import { ISSUE_TYPES, QUERY_OPERAND } from "@/constants"

export const beforeIssue = async (to, from) => {
  const isNavigatingFromOutside         = !from.name
  const isNavigatingFromIssueChildRoute = ["issue-accesses", "issue-print"].includes(from.name)
  const isNavigatingFromIssue           = from.name === "issue"
  const isNavigatingToTask              = to.name === "task"
  const isNavigatingFromAnotherIssue    = isNavigatingFromIssue && from.params.id !== to.params.id

  let shouldLoadUsers  = false
  let shouldLoadFields = false

  const hasToLoadData = isNavigatingFromOutside ||
    isNavigatingFromAnotherIssue ||
    (!isNavigatingFromIssue && !isNavigatingFromIssueChildRoute || isNavigatingToTask)

  if (hasToLoadData) {
    store.commit("issues/setCommentAdded", false)
    store.commit("issues/setAddingComment", false)
    store.commit("issues/setCommentRemoved", false)
    store.commit("issues/setRemovingComment", false)
    store.commit("messages/setMessageAdded", false)
    store.commit("messages/setAddingMessage", false)
    store.commit("messages/setMessageRemoved", false)
    store.commit("messages/setRemovingMessage", false)
    store.commit("messageItems/setMessageItemAdded", false)
    store.commit("messageItems/setAddingMessageItem", false)
    store.commit("issues/resetUpdatingIssue")
    store.commit("issues/resetIssueUpdated")
    store.commit("reports/resetReportUpdated")
    store.commit("reports/resetUpdatingReport")
    store.commit("messages/resetMessageUpdated")
    store.commit("messages/resetUpdatingMessage")
    store.commit("messageItems/resetMessageItemUpdated")
    store.commit("messageItems/resetUpdatingMessageItem")

    const loadIssuePromise = store.dispatch("issues/loadIssue", +to.params.id)

    const accessPromise = store.dispatch("accessControl/loadLoggedInUserAccess", [{
      policies: [
        "Issue view",
        "Issue update",
        "Issue add comment",
        "Issue remove comment",
        "Issue add form instances",
        "Issue update issue field values",
        "Issue print",
        "Issue add task",
        "Issue view logs",
        "Issue add folders"
      ],
      resourceIds: [+to.params.id]
    }])

    const issueRoleIds = []
    const roles        = store.getters["roles/roles"]
    for (const role of roles) {
      if (role.issue) {
        issueRoleIds.push(role.id)
      }
    }

    await loadIssuePromise

    const issues               = store.getters["issues/issues"]
    const issue                = issues.find(issue => issue.id === +to.params.id)
    const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]

    const hasAccessViewPolicy = loggedInUserPolicies["Access view"]
    if (hasAccessViewPolicy) {
      store.dispatch("accesses/loadAccesses", {
        roleId: issueRoleIds.toString()
      }).then(() => {
        const accesses = store.getters["accesses/accesses"]
        store.dispatch("accessControl/loadLoggedInUserAccess", [{
          policies   : ["Access view"],
          resourceIds: accesses.map(access => access.id)
        }])
      })
    }

    if (issue) {
      const loadIssuesPromise = store.dispatch("issues/loadIssues", {
        properties: "id,summary,parentId,statusId,assigneeId,dueDate,domainId"
      })

      const isACase = issue.typeId === ISSUE_TYPES[0].id
      if (to.name === "issue" && isACase) {
        store.commit("shared/setBreadcrumbs", ISSUE(to))
        store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CASE))
      } else {
        to.params.issueId = issue.parentId
        store.commit("shared/setBreadcrumbs", TASK(to))
        store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.TASK))
      }

      const groups   = new Set()
      const accesses = store.getters["accesses/accesses"]
      for (const access of accesses) {
        if (access.groupId) {
          groups.add(access.groupId)
        }
      }
      for (const group of groups) {
        store.dispatch("groups/loadUsers", group)
      }

      //TODO call this based on access to issue id or domain id
      if (loggedInUserPolicies["Issue view logs"]) {
        store.dispatch("issues/loadLogs", issue.id)
      }

      Promise.all([loadIssuesPromise, accessPromise])
        .then(() => {
          if (loggedInUserPolicies["Label view"]) {
            store.dispatch("labels/loadLabels")
          }

          const hasFolderViewPolicy = loggedInUserPolicies["Folder view"]
          if (hasFolderViewPolicy) {
            store.dispatch("folders/loadFolders", {
              issueId: issue.id
            }).then(() => {
              const folders               = store.getters["folders/folders"]
              const foldersOfCurrentIssue = folders.filter(folder =>
                folder.issueId === +to.params.id
              )
              if (foldersOfCurrentIssue.length) {
                store.dispatch("accessControl/loadLoggedInUserAccess", [{
                  policies   : ["Folder remove", "Folder update"],
                  resourceIds: foldersOfCurrentIssue.map(folder => folder.id)
                }])
              }
            })
          }

          const allowedIssuesAndDomainsForIssueViewCommentsPolicy = store.getters["accessControl/allowedIssuesAndDomainsForIssueViewCommentsPolicy"]
          let shouldLoadComments
          if (!isACase) {
            const parentIssueId = issue.parentId
            const parentIssue   = issues.find(issue => issue.id === parentIssueId)
            shouldLoadComments  = allowedIssuesAndDomainsForIssueViewCommentsPolicy.ids.includes(parentIssueId) ||
              allowedIssuesAndDomainsForIssueViewCommentsPolicy.domainIds.includes(parentIssue.domainId)
          } else {
            shouldLoadComments = allowedIssuesAndDomainsForIssueViewCommentsPolicy.domainIds.includes(issue.domainId)
              || allowedIssuesAndDomainsForIssueViewCommentsPolicy.ids.includes(issue.id)
          }

          if (shouldLoadComments) {
            store.dispatch("issues/loadComments", issue.id)

            if (loggedInUserPolicies["User view"] && !shouldLoadUsers) {
              shouldLoadUsers = true
            }
          }

          const hasIssueDocumentViewPolicy = loggedInUserPolicies["IssueDocument view download url"] && loggedInUserPolicies["IssueDocument view"]
          if (hasIssueDocumentViewPolicy) {
            const issueIdsAndDomainIdsForIssueDocumentView = store.getters["accessControl/allowedIssuesAndDomainsForIssueDocumentViewPolicy"]
            let shouldLoadDocuments

            if (!isACase) {
              const parentIssueId = issue.parentId
              const parentIssue   = issues.find(issue => issue.id === parentIssueId)
              shouldLoadDocuments = issueIdsAndDomainIdsForIssueDocumentView.ids.includes(parentIssueId) ||
                issueIdsAndDomainIdsForIssueDocumentView.domainIds.includes(parentIssue.domainId)
            } else {
              shouldLoadDocuments = issueIdsAndDomainIdsForIssueDocumentView.ids.includes(issue.id) ||
                issueIdsAndDomainIdsForIssueDocumentView.domainIds.includes(issue.domainId)
            }

            if (shouldLoadDocuments) {
              if (loggedInUserPolicies["User view"] && !shouldLoadUsers) {
                shouldLoadUsers = true
              }

              store.dispatch("issueDocuments/loadIssueDocuments", {
                issueId: issue.id
              }).then(() => {
                const issueDocuments               = store.getters["issueDocuments/issueDocuments"]
                const issueDocumentsOfCurrentIssue = issueDocuments.filter(issueDocument =>
                  issueDocument.issueId === +to.params.id
                )

                if (issueDocumentsOfCurrentIssue.length) {
                  store.dispatch("accessControl/loadLoggedInUserAccess", [{
                    policies   : ["IssueDocument remove", "IssueDocument update"],
                    resourceIds: issueDocumentsOfCurrentIssue.map(issueDocument => issueDocument.id)
                  }])
                }
              })
              if (loggedInUserPolicies["IssueDocument add"]) {
                store.dispatch("accessControl/loadLoggedInUserAccess", [{
                  policies   : ["Issue add documents"],
                  resourceIds: issues.map(issue => issue.id)
                }])
              }
            }
          }
          const issueViewPolicies = store.getters["accessControl/issueViewPolicies"]
          const  issueViewPolicy  = issueViewPolicies.find(issueViewPolicy =>
            issueViewPolicy.id === issue.id
          )
          const canViewAssignee   = issueViewPolicy.select.includes("assigneeId")

          if (canViewAssignee && loggedInUserPolicies["User view"] && !shouldLoadUsers) {
            shouldLoadUsers = true
          }

          if (isACase) {
            const canViewDomain = issueViewPolicy.select.includes("domainId")

            if (canViewDomain && loggedInUserPolicies["Domain view"]) {
              store.dispatch("domains/loadDomains")
            }
            const allowedIssuesAndDomainsForIssueFieldViewPolicy = store.getters["accessControl/allowedIssuesAndDomainsForIssueFieldViewPolicy"]

            if (allowedIssuesAndDomainsForIssueFieldViewPolicy.domainIds.includes(issue.domainId)
              || allowedIssuesAndDomainsForIssueFieldViewPolicy.ids.includes(issue.id)) {

              if (loggedInUserPolicies["FieldV2 view"] && !shouldLoadFields) {
                shouldLoadFields = true
              }

              store.dispatch("issueFields/loadIssueFields").then(() => {
                const issueFields                     = store.getters["issueFields/issueFields"]
                const issueFieldsIds                  = issueFields.map(issueField => issueField.id).toString()
                const loadIssueFieldValuesQueryParams = {
                  issueId     : issue.id,
                  issueFieldId: issueFieldsIds
                }
                store.dispatch("issueFieldValues/loadIssueFieldValues", loadIssueFieldValuesQueryParams)
              })
            }

            const allowedIssuesAndDomainsForIssueLinkViewPolicy = store.getters["accessControl/allowedIssuesAndDomainsForIssueLinkViewPolicy"]
            if (allowedIssuesAndDomainsForIssueLinkViewPolicy.domainIds.includes(issue.domainId)
              || allowedIssuesAndDomainsForIssueLinkViewPolicy.ids.includes(issue.id)) {
              store.dispatch("issueLinks/loadIssueLinks", {
                fromId : issue.id,
                toId   : issue.id,
                operand: QUERY_OPERAND.OR
              }).then(() => {
                const issueLinks      = store.getters["issueLinks/issueLinks"]
                const idsOfIssueLinks = issueLinks.map(issueLink =>issueLink.id )
                if (idsOfIssueLinks.length) {
                  store.dispatch("accessControl/loadLoggedInUserAccess", [{
                    policies   : ["IssueLink remove"],
                    resourceIds: idsOfIssueLinks
                  }])
                }
              })

              store.dispatch("accessControl/loadLoggedInUserAccess", [{
                policies: [
                  "Issue add link"
                ],
                resourceIds: issues.map(issue => issue.id)
              }])
            }
            //TODO call this based on access
            if (loggedInUserPolicies["OptionListItem view"]) {
              store.dispatch("optionListItems/loadOptionListItems")
            }
          }

          if (shouldLoadUsers) {
            store.dispatch("users/loadUsers")
          }

          const allowedIssuesAndDomainsForReportViewPolicy = store.getters["accessControl/allowedIssuesAndDomainsForReportViewPolicy"]
          let loadReportsPromise
          if (allowedIssuesAndDomainsForReportViewPolicy.ids.includes(issue.id) ||
            allowedIssuesAndDomainsForReportViewPolicy.domainIds.includes(issue.domainId)) {
            loadReportsPromise = store.dispatch("reports/loadReports", {
              issueId: issue.id
            })

            loadReportsPromise.then(() => {
              const reports = store.getters["reports/reports"]
              const report  = reports.find(report => report.issueId === +to.params.id)
              if (report) {
                store.dispatch("accessControl/loadLoggedInUserAccess", [{
                  policies   : ["Report view", "Report add messages", "Report update", "Report content clear"],
                  resourceIds: [report.id]
                }])
                if (loggedInUserPolicies["Channel view"]) {
                  store.dispatch("channels/loadChannel", report.channelId)
                }

                const allowedIssuesAndDomainsForMessageViewPolicy = store.getters["accessControl/allowedIssuesAndDomainsForMessageViewPolicy"]
                if (allowedIssuesAndDomainsForMessageViewPolicy.ids.includes(issue.id) ||
                  allowedIssuesAndDomainsForMessageViewPolicy.domainIds.includes(issue.domainId)) {
                  store.dispatch("messages/loadMessages", {
                    reportId: report.id
                  }).then(() => {
                    const messages            = store.getters["messages/messages"]
                    const idsOfReportMessages = new Array()
                    for (const message of messages) {
                      if (message.reportId === report.id) {
                        idsOfReportMessages.push(message.id)
                      }
                    }
                    store.dispatch("accessControl/loadLoggedInUserAccess", [{
                      policies   : ["Message update", "Message remove"],
                      resourceIds: idsOfReportMessages
                    }])
                    store.dispatch("messageItems/loadMessageItems", {
                      messageId: idsOfReportMessages.toString()
                    })
                      .then(() => {
                        const messageItems            = store.getters["messageItems/messageItems"]
                        const idsOfReportMessageItems = new Array()
                        for (const messageItem of messageItems) {
                          if (idsOfReportMessages.includes(messageItem.messageId)) {
                            idsOfReportMessageItems.push(messageItem.id)
                          }
                        }
                        store.dispatch("accessControl/loadLoggedInUserAccess", [{
                          policies   : ["MessageItem add translations", "MessageItem update"],
                          resourceIds: idsOfReportMessageItems
                        }])
                      })

                    if (loggedInUserPolicies["ReplyTemplate view"]) {
                      store.dispatch("replyTemplates/loadReplyTemplates")
                    }
                    store.dispatch("translations/loadTranslations", {
                      issueId: issue.id
                    })
                      .then(() => {
                        const translations           = store.getters["translations/translations"]
                        const idsOfIssueTranslations = new Array()
                        for (const translation of translations) {
                          if (translation.issueId === +to.params.id) {
                            idsOfIssueTranslations.push(translation.id)
                          }
                        }
                        if (idsOfIssueTranslations.length) {
                          store.dispatch("accessControl/loadLoggedInUserAccess", [{
                            policies   : ["Translation update"],
                            resourceIds: idsOfIssueTranslations
                          }])
                        }
                      })
                  })
                }
              }
            })
          }

          if (loggedInUserPolicies["FormInstance view"]) {
            const issueAndDomainIdsForViewFormInstancePolicy = store.getters["accessControl/allowedIssuesAndDomainsForFormInstanceViewPolicy"]

            const shouldLoadFormInstances = issueAndDomainIdsForViewFormInstancePolicy.ids.includes(issue.id) ||
                issueAndDomainIdsForViewFormInstancePolicy.domainIds.includes(issue.domainId)

            if (shouldLoadFormInstances) {
              let loadFormInstancesQueryParams = { issueId: issue.id }

              const issueAddFormInstancesPolicies        = store.getters["accessControl/issueAddFormInstancesPolicies"]
              const currentIssueAddFormInstancesPolicies = issueAddFormInstancesPolicies
                .find(issueAddFormInstancesPolicy => issueAddFormInstancesPolicy.id === issue.id)
              if (currentIssueAddFormInstancesPolicies?.set?.add !== undefined) {
                shouldLoadFields = true
              }

              if (loadReportsPromise) {
                loadReportsPromise.then(() => {
                  const reports = store.getters["reports/reports"]
                  const report  = reports.find(report => report.issueId === +to.params.id)
                  if (report) {
                    loadFormInstancesQueryParams = {
                      ...loadFormInstancesQueryParams,
                      reportId: report.id,
                      operand : QUERY_OPERAND.OR
                    }
                  }
                  store.dispatch("formInstances/loadFormInstances", loadFormInstancesQueryParams).then(() => {
                    const formInstances           = store.getters["formInstances/formInstances"]
                    const idsOfIssueFormInstances = formInstances
                      .filter(formInstance => formInstance.issueId === issue.id)
                      .map(formInstance => formInstance.id)

                    store.dispatch("accessControl/loadLoggedInUserAccess", [{
                      policies   : ["FormInstance update", "FormInstance remove"],
                      resourceIds: idsOfIssueFormInstances
                    }])
                  })
                })
              } else {
                store.dispatch("formInstances/loadFormInstances", loadFormInstancesQueryParams)
                  .then(() => {
                    const formInstances           = store.getters["formInstances/formInstances"]
                    const idsOfIssueFormInstances = formInstances
                      .filter(formInstance => formInstance.issueId === issue.id)
                      .map(formInstance => formInstance.id)

                    store.dispatch("accessControl/loadLoggedInUserAccess", [{
                      policies   : ["FormInstance update", "FormInstance remove"],
                      resourceIds: idsOfIssueFormInstances
                    }])
                  })
              }
            }
          }
          //TODO check and move it wrt to the access
          if (loggedInUserPolicies["FormTemplate view"]) {
            store.dispatch("formTemplates/loadFormTemplates", {
              reportForm: BOOLEAN.FALSE
            }).then(() => {
              const issueFormTemplates = store.getters["formTemplates/issueFormTemplates"]
              if (issueFormTemplates.length) {
                if (loggedInUserPolicies["FormTemplateConfiguration view"]) {
                  store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
                    formTemplateId: issueFormTemplates.map(formTemplate => formTemplate.id).toString()
                  })
                }
              }
            })
          }

          if (shouldLoadFields) {
            store.dispatch("fields/loadFieldsV2")
          }
        })
    } else {
      const isNotFoundError  = store.getters["shared/notFoundError"]
      const isForbiddenError = store.getters["shared/forbiddenError"]
      if (isNotFoundError) {
        return {
          name: "not-found"
        }
      } else if (isForbiddenError) {
        return {
          name: "forbidden"
        }
      } else {
        return {
          name: "issues"
        }
      }
    }

    store.dispatch("configurations/loadConfigurations")

    if (loggedInUserPolicies["Group view"]) {
      store.dispatch("groups/loadGroups")
    }

    if (loggedInUserPolicies["DataRetentionPeriod view"]) {
      store.dispatch("dataRetentionPeriods/loadDataRetentionPeriods")
    }
    if (loggedInUserPolicies["WorkflowAssociation view"]) {
      store.dispatch("workflowAssociations/loadWorkflowAssociations")
    }
  }
}