/**
 * @file It exports a method that should be executed before changing routes.
 */
import { beforeIssue } from "@/middleware/before-issue"
import { beforeIssues } from "@/middleware/before-issues"
import { beforeIssueAccesses } from "@/middleware/before-issue-accesses"

import { beforeDomains } from "@/middleware/before-domains"
import { beforeDomain } from "@/middleware/before-domain"

import { beforeChannels } from "@/middleware/before-channels"
import { beforeChannel } from "@/middleware/before-channel"
import { beforeChannelAdd } from "@/middleware/before-channel-add"

import { beforeGroups } from "@/middleware/before-groups"
import { beforeGroup } from "@/middleware/before-group"

import { beforeUsers } from "@/middleware/before-users"
import { beforeUser } from "@/middleware/before-user"
import { beforeUserAdd } from "@/middleware/before-user-add"

import { beforeDashboard } from "@/middleware/before-dashboard"
import { beforeUserSettings } from "./before-user-settings"
import { beforeConfigurations } from "@/middleware/before-configurations"
import { beforeExceptions } from "@/middleware/before-exceptions"
import { beforeLogs } from "@/middleware/before-logs"
import { beforeLogin } from "@/middleware/before-login"
import { beforeResetPassword } from "@/middleware/before-reset-password"
import { beforeFirstLoad } from "@/middleware/before-first-load"
import { beforeNotFound } from "@/middleware/before-not-found"
import { beforeProtectedRoute } from "@/middleware/before-protected-route"
import { beforeExport } from "@/middleware/before-export"
import { beforeOptionList } from "@/middleware/before-option-list"
import { beforeReporterIntakeForms } from "@/middleware/before-reporter-intake-forms"
import { beforeReporterIntakeForm } from "@/middleware/before-reporter-intake-form"

import { beforeRoles } from "@/middleware/before-roles"
import { beforeRole } from "@/middleware/before-role"
import { beforeOptionLists } from "@/middleware/before-option-lists"
import { beforeAnalytics } from "@/middleware/before-analytics"
import { beforeSupport } from "@/middleware/before-support"
import { beforeFields } from "@/middleware/before-fields"
import { beforeIssueForms } from "@/middleware/before-issue-forms"
import { beforeIssueForm } from "@/middleware/before-issue-form"
import { beforeIssueFields } from "@/middleware/before-issue-fields"
import { beforeIssuePrint } from "@/middleware/before-issue-print"

import { beforeAutomations } from "@/middleware/before-automations"
import { beforeAutomation } from "@/middleware/before-automation"

import { beforeWorkflow } from "@/middleware/before-workflow"
import { beforeWorkflows } from "@/middleware/before-workflows"

import { beforeTransition } from "@/middleware/before-transition"

import { beforeScreens } from "@/middleware/before-screens"
import { beforeScreen } from "@/middleware/before-screen"

import { beforeReplyTemplates } from "@/middleware/before-reply-templates"
import { beforeReplyTemplate } from "@/middleware/before-reply-template"

import { beforeFilters } from "@/middleware/before-filters"

export default async (to, from, next) => {
  let newRoute
  if (to.name === "not-found") {
    newRoute = await beforeNotFound()
  } else if (to.name !== "logout") {
    newRoute = await beforeFirstLoad(to, from)
    if (!newRoute) {
      if (to.meta?.protected) {
        newRoute = await beforeProtectedRoute(to, from)
        if (!newRoute) {
          if (to.name === "dashboard") {
            newRoute = await beforeDashboard()
          } else if (to.name === "configurations") {
            newRoute = await beforeConfigurations(to, from)
          } else if (to.name === "domains") {
            newRoute = await beforeDomains(to, from)
          } else if (to.name === "channels") {
            newRoute = await beforeChannels()
          } else if (to.name === "channel" || to.name === "domain-channel") {
            newRoute = await beforeChannel(to, from)
          } else if (to.name === "users") {
            newRoute = await beforeUsers()
          } else if (to.name === "user-add") {
            newRoute = await beforeUserAdd()
          } else if (to.name === "user") {
            newRoute = await beforeUser(to, from)
          } else if (to.name === "groups") {
            newRoute = await beforeGroups(to, from)
          } else if (to.name === "group") {
            newRoute = await beforeGroup(to, from)
          } else if (to.name === "issues") {
            newRoute = await beforeIssues(to, from)
          } else if (to.name === "channel-add") {
            newRoute = await beforeChannelAdd()
          } else if (to.name === "issue" || to.name === "task") {
            newRoute = await beforeIssue(to, from)
          } else if (to.name === "roles") {
            newRoute = await beforeRoles()
          } else if (to.name === "role") {
            newRoute = await beforeRole(to, from)
          } else if (to.name === "issue-accesses") {
            newRoute = await beforeIssueAccesses(to, from)
          } else if (to.name === "domain" && from.name !== "domain") {
            newRoute = await beforeDomain(to, from)
          } else if (to.name === "user-settings") {
            newRoute = await beforeUserSettings(to, from)
          } else if (to.name === "exceptions") {
            newRoute = await beforeExceptions()
          } else if (to.name === "logs") {
            newRoute = await beforeLogs()
          } else if (to.name === "export") {
            newRoute = await beforeExport(to, from)
          } else if (to.name === "option-list") {
            newRoute = await beforeOptionList(to)
          } else if (to.name === "option-lists") {
            newRoute = await beforeOptionLists()
          } else if (to.name === "reporter-intake-forms") {
            newRoute = await beforeReporterIntakeForms()
          } else if (to.name === "analytics") {
            newRoute = await beforeAnalytics(to, from)
          } else if (to.name === "support") {
            newRoute = await beforeSupport()
          } else if (to.name === "reporter-intake-form" || to.name === "reporter-intake-form-template-configuration") {
            newRoute = await beforeReporterIntakeForm(to, from)
          } else if (to.name === "fields" || to.name === "field") {
            newRoute = await beforeFields(to, from)
          } else if (to.name === "issue-forms") {
            newRoute = await beforeIssueForms()
          } else if (to.name === "issue-form" || to.name === "issue-form-field-configuration") {
            newRoute = await beforeIssueForm(to, from)
          } else if (to.name === "issue-fields" || to.name === "issue-field") {
            newRoute = await beforeIssueFields(to, from)
          } else if (to.name === "issue-print") {
            newRoute = await beforeIssuePrint(to, from)
          } else if (to.name === "automations") {
            newRoute = await beforeAutomations()
          } else if (to.name === "automation-add" || to.name === "automation-edit") {
            newRoute = await beforeAutomation(to)
          } else if (to.name === "workflow" || to.name === "workflow-status-side-panel") {
            newRoute = await beforeWorkflow(to, from)
          } else if (to.name === "workflows") {
            newRoute = await beforeWorkflows()
          } else if (to.name === "transition") {
            newRoute = await beforeTransition(to, from)
          } else if (to.name === "screens") {
            newRoute = await beforeScreens()
          } else if (to.name === "screen" || to.name === "screen-item-side-panel") {
            newRoute = await beforeScreen(to, from)
          } else if (to.name === "reply-templates") {
            newRoute = await beforeReplyTemplates()
          } else if (to.name === "reply-template") {
            newRoute = await beforeReplyTemplate(to)
          } else if (to.name === "filters") {
            newRoute = await beforeFilters()
          }
        }
      } else if (to.name === "login") {
        newRoute = await beforeLogin(to, from)
      } else if (to.name === "reset-password") {
        newRoute = await beforeResetPassword(to, from)
      }
    }
  }
  if (!newRoute) {
    next()
  } else {
    next(newRoute)
  }
}
